import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

import { useCustomer } from 'contexts/customer-context';
import { useCart } from 'contexts/cart-context';
import formatPrice from 'utils/formatPrice';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Loader from 'components/Loader';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import * as S from './style';

import PaymentForm from "../../commons/PaymentForm";

function Pay() {


  const { products, total } = useCart();
  const { customerBilling, customerDelivery,customerLogin } = useCustomer();
  const [ isFetching , setIsFetching ] = useState(false);

  const navigate = useNavigate();

  const squareAppID = `${process.env.REACT_APP_SQUAREUP_APP_ID}`;
  const squareLocationID = `${process.env.REACT_APP_SQUAREUP_LOCATION_ID}`;


  const billingContact = {
    addressLines: [customerBilling.address_line1, customerBilling.address_line2],
    familyName: customerBilling.customerSurname,
    givenName: customerBilling.customerFirstName,
    countryCode: 'GB',
    city: customerBilling.town,
  }

  var delivery = parseFloat( `${process.env.REACT_APP_CHARGE_DELIVERY}` );
  if (customerDelivery.pickup) {
    delivery = 0;
  }

  const handlePayment = (cardDetails:any, buyer:any) => {

    setIsFetching(true);
    const sendCardDetail = cardDetails;

    const isProduction = process.env.NODE_ENV === 'production';

    sendCardDetail.nonce = sendCardDetail.token;

    const squareDetails = {
      cardDetails: cardDetails,
      total: total.totalPrice + delivery,
      customer: customerDelivery,
      billingAddress: customerBilling,
      orderitems: getCartOrderItems(products),
      ordertotal: total,
      delivery: delivery,
    };

    const squareJson = JSON.stringify(squareDetails);

    let url = process.env.REACT_APP_SQUAREUP_BACKEND_PRODUCTION;
    let crmurl = process.env.REACT_APP_BASEURL_PRODUCTION;

    if (!isProduction) {
      url = process.env.REACT_APP_SQUAREUP_BACKEND_DEVELOPMENT;
      crmurl = process.env.REACT_APP_BASEURL_DEVELOPMENT;
    }

    axios.post(url + '/charge', squareJson ).then(
      (rsp) => {

        const orderDetails = {
          customer: customerDelivery,
          billingAddress: customerBilling,
          orderitems: getCartOrderItems(products),
          ordertotal: total,
          delivery: delivery,
          paymentdetails:
              {
                  status: rsp.data.data.response.payment.status,
                  state: rsp.data.data.response.payment.status,
                  transaction_id: rsp.data.data.response.payment.id,
                  client_transaction_id: rsp.data.data.response.payment.order_id

              }
          };

          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + customerLogin.token
          }

          const jsonbody = JSON.stringify(orderDetails);

          console.log(jsonbody);

          axios.post(crmurl + '/api/wine-create-order', jsonbody , {headers: headers }).then(
            (rsp) => {
              setIsFetching(true);
              navigate("/paymentthanks");
            })



      }).catch(
        err => {
          setIsFetching(true);
          toast.error('Sorry there seems to be an issue, please try again later');
        }
    )


  }

  const getCartOrderItems = (products:any[]) => {
    var orderItems:any[] = [];
    console.log(products);
    products.forEach(product => {
      console.log(product)
      orderItems.push(
        {
            id:  product.id,
            qty: product.quantity,
            price: product.retail_price,
            name: product.name
        }
    )
    });
    return orderItems;
  }

  const formatCartPrice = (price: number, currencyId: string, delivery: number): string => {

    let retStr = "";



    if (delivery > 0) {
      retStr = (price+delivery).toFixed(2) + " ( delivery " + currencyId + delivery.toFixed(2) + ')' ;
    } else {
      retStr = price.toFixed(2);
    }

    return retStr;
  };

  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header/>
      <S.OneColumnGrid>
        <S.Main>
          <S.MainTitle>MAKE <i>your</i> <br/>PAYMENT</S.MainTitle>


          <PaymentForm
            amount={total.totalPrice.toString()}
            displayAmount={`${total.currencyFormat} ${formatCartPrice(
                total.totalPrice,
                total.currencyFormat,
                delivery
              )}`}
              squareAppID={ squareAppID.toString() }
              squareLocationID= {squareLocationID.toString()}
              billingContact={billingContact}
              handlePayment={handlePayment}
              />

        </S.Main>
      </S.OneColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default Pay;
