import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import * as S from './style';

function App() {

  const navigate = useNavigate();


  const loginClick = () => {
    navigate("/login",{ state: 'choosedelivery' });
  }

  return (
    <S.Container>
      <Header/>
      <S.OneColumnGrid>
        <S.Main>
          <S.MainTitle>LOG <i>in</i> <br/>REQUIRED</S.MainTitle>

          <S.DisplayText>To check out and pay you will need to login or register to continue</S.DisplayText>


          <S.ActionButton onClick={loginClick}>CONTINUE</S.ActionButton>

        </S.Main>
      </S.OneColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default App;
