import { createContext, useContext, FC, useState,useEffect } from 'react';

import { IGetCustomerLoginResponse,IGetCustomerPortfolioResponse,IGetCustomerProfileResponse,IGetCustomerDeliveryResponse,IGetCustomerBillingResponse } from 'models';

export interface ICustomerContext {
  customerLogin: IGetCustomerLoginResponse;
  setCustomerLogin(products: IGetCustomerLoginResponse): void;
  customerPortfolio: IGetCustomerPortfolioResponse;
  setCustomerPortfolio(portfolio: IGetCustomerPortfolioResponse): void;
  customerProfile: IGetCustomerProfileResponse;
  setCustomerProfile(portfolio: IGetCustomerProfileResponse): void;
  customerDelivery: IGetCustomerDeliveryResponse;
  setCustomerDelivery(delivery: IGetCustomerDeliveryResponse): void;
  customerBilling: IGetCustomerBillingResponse;
  setCustomerBilling(delivery: IGetCustomerBillingResponse): void;
}

const CustomerContext = createContext<ICustomerContext | undefined>(undefined);
const useCustomerContext = (): ICustomerContext => {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error(
      'useCustomerContext must be used within a CustomerProvider'
    );
  }

  return context;
};


const totalInitialValues = {
  loggedIn: false,
  lastLogin: '',
  username: '',
  passwordkey: '',
  token: '',
  lastUpdated: '',
};



const CustomerProvider: FC = (props) => {
  const customer =  totalInitialValues;
  const [customerLogin, setCustomerLogin] = useState<IGetCustomerLoginResponse>( ( JSON.parse(window.sessionStorage.getItem("customerLogin") || '{}') )  );
  const [customerProfile, setCustomerProfile] = useState<IGetCustomerProfileResponse>( (JSON.parse(window.sessionStorage.getItem("customerProfile") || '{}')));
  const [customerPortfolio, setCustomerPortfolio] = useState<IGetCustomerPortfolioResponse>( (JSON.parse(window.sessionStorage.getItem("customerProfile") || '{}')) );
  const [customerDelivery, setCustomerDelivery] = useState<IGetCustomerDeliveryResponse>( (JSON.parse(window.sessionStorage.getItem("customerProfile") || '{}')) );
  const [customerBilling, setCustomerBilling] = useState<IGetCustomerBillingResponse>( (JSON.parse(window.sessionStorage.getItem("customerProfile") || '{}')) );

  const CustomerContextValue: ICustomerContext = {
    customerLogin,
    setCustomerLogin,
    customerPortfolio,
    setCustomerPortfolio,
    customerProfile,
    setCustomerProfile,
    customerDelivery,
    setCustomerDelivery,
    customerBilling,
    setCustomerBilling
  };


  useEffect(() => { setCustomerLogin(JSON.parse(window.sessionStorage.getItem("customerLogin") || '{}')); }, []);
  useEffect(() => { window.sessionStorage.setItem("customerLogin", JSON.stringify(customerLogin));  }, [customerLogin]);

  useEffect(() => { setCustomerPortfolio(JSON.parse(window.sessionStorage.getItem("customerProfile") || '{}')); }, []);
  useEffect(() => { window.sessionStorage.setItem("customerProfile", JSON.stringify(customerProfile));  }, [customerProfile]);

  useEffect(() => { setCustomerProfile(JSON.parse(window.sessionStorage.getItem("customerProfile") || '{}')); }, []);
  useEffect(() => { window.sessionStorage.setItem("customerProfile", JSON.stringify(customerProfile));  }, [customerProfile]);

  useEffect(() => { setCustomerDelivery(JSON.parse(window.sessionStorage.getItem("customerDelivery") || '{}')); }, []);
  useEffect(() => { window.sessionStorage.setItem("customerDelivery", JSON.stringify(customerDelivery));  }, [customerDelivery]);

  useEffect(() => { setCustomerBilling(JSON.parse(window.sessionStorage.getItem("customerBilling") || '{}')); }, []);
  useEffect(() => { window.sessionStorage.setItem("customerBilling", JSON.stringify(customerBilling));  }, [customerBilling]);

  return <CustomerContext.Provider value={CustomerContextValue} {...props} />;
};

export { CustomerProvider, useCustomerContext };
