import { useState } from 'react';

import { useNavigate,useLocation } from "react-router-dom";

import Loader from 'components/Loader';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useCustomer } from 'contexts/customer-context';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import axios from 'axios';
import { toast } from 'react-toastify';

import * as S from './style';

function App() {

  const navigate = useNavigate();

  const defaultValues = {
    email: "oenouser@yopmail.com",
    password: "password",
  };

  const { state } = useLocation();

  console.log(state);

  const [formValues, setFormValues] = useState(defaultValues);
  const [ isFetching , setIsFetching ] = useState(false);
  const isProduction = process.env.NODE_ENV === 'production';

  const { setCustomerLogin,customerLogin,setCustomerPortfolio,setCustomerProfile } = useCustomer();


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log(formValues);

    let url = process.env.REACT_APP_BASEURL_PRODUCTION;

    if (!isProduction) {
      url = process.env.REACT_APP_BASEURL_DEVELOPMENT;
    }

    setIsFetching(true);

    axios.post(
      url + '/api/phone-login',
    {
      email: formValues.email,
      password: formValues.password,
    }).then(
      (rsp) => {
        if (rsp.data.statuscode == 200) {

            var token = rsp.data.data.token;


            setCustomerLogin(rsp.data.data);

            // Now get the users profile
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + rsp.data.data.token
            }

            axios.get(url + '/api/get-portfolio' , { headers: headers}).then(
              (rsp) => {

                setCustomerPortfolio(rsp.data.data);

              }).catch(
                err => {
                  console.log(err);
                }
              );


            axios.get(url + '/api/get-profile' , { headers: headers}).then(
              (rsp) => {

                setCustomerProfile(rsp.data.data.user);

                setIsFetching(false);

                if (state == null) {
                  navigate("/");
                } else {
                  navigate("/" + state);
                }



              }).catch(
                err => {
                  setIsFetching(false);
                  toast.error('Sorry there seems to be an issue, please try again later');
                }
              )





          } else {
            setIsFetching(false);

            toast.error('Sorry there seems to be an issue, please try again later');
          }

      }
    ).catch(
      err => {
        console.log(err);
        setIsFetching(false);
        toast.error('Sorry there seems to be an issue, please try again later');
      }
    )


  }



  const registerClick = () => {
    navigate("/register");
  }
  const resetClick = () => {
    navigate("/resetpassword");
  }


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    console.log(name,value)
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header/>
      <S.TwoColumnGrid>
        <S.Main>
          <S.MainTitle>LOG <i>in</i></S.MainTitle>

          <form onSubmit={handleSubmit}>

            <TextField value={formValues.email} name="email" fullWidth label="E-MAIL" variant="standard" required type="email"  onChange={handleInputChange}/><br/><br/>
            <TextField value={formValues.password} name="password" fullWidth label="PASSWORD" variant="standard" type="password" required onChange={handleInputChange}/><br/><br/><br/><br/>

            <S.ActionLink onClick={resetClick}>Have you forgotten your password?</S.ActionLink>

            <S.ActionButton type="submit">LOG IN</S.ActionButton>

          </form>
        </S.Main>
        <S.Main>
            <S.MainTitle>REGISTER</S.MainTitle>

            <S.DisplayText>If you still don’t have an OENO account, use this option to access the registration form for a faster and more enjoyable experience.</S.DisplayText>

            <S.ActionButton  onClick={registerClick}>CREATE ACCOUNT</S.ActionButton>

        </S.Main>
      </S.TwoColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default App;
