import * as S from './style';
import { useNavigate } from "react-router-dom";
import { useCustomer } from 'contexts/customer-context';

const Profile = () => {

  const navigate = useNavigate();

  const { customerLogin } = useCustomer();

  const profileClick = () => {

    if (!customerLogin.hasOwnProperty('token')) {
      navigate("/login");
    } else if (customerLogin.token == '') {
      navigate("/login");
    } else {
      navigate("/profile");
    }


  }

  return (
    <S.ProfileContainer onClick={profileClick}>
      <S.ProfileIcon />
    </S.ProfileContainer>

  );
};

export default Profile;
