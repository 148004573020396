// Dependencies
import * as React from 'react';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

interface IProps {
  amount: string;
  displayAmount: string;
  squareAppID: string;
  squareLocationID: string;
  billingContact: Object;
  handlePayment(cardDetails:any, buyer:any): void;

}

const darkModeCardStyle = {
  '.input-container': {
    borderColor: '#000000',
    borderRadius: '6px',
  },
  '.input-container.is-focus': {
    borderColor: '#000000',
  },

};

const MyPaymentForm = ({ amount,displayAmount,squareAppID,squareLocationID,billingContact,handlePayment }: IProps) => (
  <PaymentForm
    /**
     * Identifies the calling form with a verified application ID generated from
     * the Square Application Dashboard.
     */
    applicationId={squareAppID}
    /**
     * Invoked when payment form receives the result of a tokenize generation
     * request. The result will be a valid credit card or wallet token, or an error.
     */
    cardTokenizeResponseReceived={(cardDetails:any, buyer:any) => {

      handlePayment(cardDetails, buyer);
    }}
    /**
     * This function enable the Strong Customer Authentication (SCA) flow
     *
     * We strongly recommend use this function to verify the buyer and reduce
     * the chance of fraudulent transactions.
     */
    createVerificationDetails={() => ({
      amount: amount,
      /* collected from the buyer */
      billingContact: billingContact,
      currencyCode: 'GBP',
      intent: 'CHARGE',
    })}
    /**
     * Identifies the location of the merchant that is taking the payment.
     * Obtained from the Square Application Dashboard - Locations tab.
     */
    locationId={squareLocationID}
  >
    <CreditCard style={darkModeCardStyle}>PAY {displayAmount}</CreditCard>

  </PaymentForm>
);

export default MyPaymentForm;
