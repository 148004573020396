import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useNavigate } from "react-router-dom";

import Loader from 'components/Loader';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useCustomer } from 'contexts/customer-context';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import axios from 'axios';
import { toast } from 'react-toastify';

import * as S from './style';

function App() {

  const navigate = useNavigate();

  const defaultValues = {
    email: "oenouser@yopmail.com",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [ isFetching , setIsFetching ] = useState(false);
  const isProduction = process.env.NODE_ENV === 'production';

  const { setCustomerLogin,customerLogin,setCustomerPortfolio,setCustomerProfile } = useCustomer();


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log(formValues);

    let url = process.env.REACT_APP_BASEURL_PRODUCTION;

    if (!isProduction) {
      url = process.env.REACT_APP_BASEURL_DEVELOPMENT;
    }

    setIsFetching(true);

    axios.post(
      url + '/api/forgotPassword',
    {
      email: formValues.email,
    }).then(
      (rsp) => {

        if (rsp.data.statuscode == 200) {

          navigate("/otppassword",{ state: formValues.email });

        } else {
          setIsFetching(false);

          toast.error('Sorry there seems to be an issue, please try again later');
        }

      }
    ).catch(
      err => {
        console.log(err);
        setIsFetching(false);
        toast.error('Sorry there seems to be an issue, please try again later');
      }
    )


  }



  const loginClick = () => {
    navigate("/login");
  }


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    console.log(name,value)
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header/>
      <S.OneColumnGrid>
        <S.Main>
          <S.MainTitle>RESET PASSWORD</S.MainTitle>

          <form onSubmit={handleSubmit}>

            <TextField value={formValues.email} name="email" fullWidth label="Email" variant="standard" required type="email"  onChange={handleInputChange}/><br/><br/><br/><br/>

            <S.ActionButton  type="submit">SEND RESET PASSWORD DETAILS</S.ActionButton><br/><br/>

          </form>

            <S.ActionButton  onClick={loginClick}>LOGIN</S.ActionButton><br/><br/>

        </S.Main>
      </S.OneColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default App;
