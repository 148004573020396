import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useNavigate,useLocation } from "react-router-dom";

import Loader from 'components/Loader';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useCustomer } from 'contexts/customer-context';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import axios from 'axios';
import { toast } from 'react-toastify';

import * as S from './style';

function App() {

  const { customerProfile, customerLogin } = useCustomer();

  const navigate = useNavigate();

  console.log(customerLogin);

  const { state } = useLocation();

  const defaultValues = {
    email: state,
    old_password: "password1",
    confirm_password: "password",
    password: "password"
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [ isFetching , setIsFetching ] = useState(false);
  const isProduction = process.env.NODE_ENV === 'production';



  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let url = process.env.REACT_APP_BASEURL_PRODUCTION;

    if (!isProduction) {
      url = process.env.REACT_APP_BASEURL_DEVELOPMENT;
    }

    setIsFetching(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + customerLogin.token
    }

    axios.post(
      url + '/api/update-password',
    {
      email: customerProfile.email,
      password: formValues.password,
      old_password: formValues.old_password,
      confirm_password: formValues.confirm_password,
    }, {headers: headers }).then(
      (rsp) => {

        if (rsp.data.statuscode == 200) {


          navigate("/profile");


        } else {
          setIsFetching(false);
          if (rsp.data.message) {
            if (rsp.data.message.confirm_password) {

              toast.error(rsp.data.message.confirm_password[0]);

            } else if (rsp.data.message.password) {

                toast.error(rsp.data.message.password[0]);

            } else  {
                toast.error(rsp.data.message);
            }
          } else {
            toast.error('Sorry there seems to be an 111111111issue, please try again later');
          }

        }

      }
    ).catch(
      err => {
        console.log(err);
        setIsFetching(false);
        toast.error('Sorry there seems to be an issue, please try again later');
      }
    )


  }



  const cancelClick = () => {
    navigate("/profile");
  }


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header/>
      <S.OneColumnGrid>
        <S.Main>
          <S.MainTitle>RESET PASSWORD</S.MainTitle>

          <form onSubmit={handleSubmit}>

            <TextField value={formValues.old_password} name="old_password" fullWidth label="Current Password" variant="standard" type="password" required onChange={handleInputChange}/><br/><br/>
            <TextField value={formValues.password} name="password" fullWidth label="New Password" variant="standard" type="password" required onChange={handleInputChange}/><br/><br/>
            <TextField value={formValues.confirm_password} name="confirm_password" fullWidth label="Confimr New Password" variant="standard" type="password" required onChange={handleInputChange}/><br/><br/>

            <S.ActionButton  type="submit">RESET</S.ActionButton><br/><br/>

          </form>

          <S.ActionButton  onClick={cancelClick}>CANCEL</S.ActionButton><br/><br/>

        </S.Main>
      </S.OneColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default App;
