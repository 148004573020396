import { useNavigate } from "react-router-dom";
import { useCustomer } from 'contexts/customer-context';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import * as S from './style';

function App() {

  const navigate = useNavigate();

  const { customerProfile,setCustomerBilling, setCustomerDelivery } = useCustomer();

  const shopDelivery = () => {

    setCustomerBilling({
      customerFirstName: customerProfile.firstname,
      customerSurname: customerProfile.surname,
      customerEmail: customerProfile.email,
      address_line1: customerProfile.address_line1,
      address_line2: customerProfile.address_line2,
      town: customerProfile.town,
      county: customerProfile.county,
      postcode: customerProfile.postcode
    })

    setCustomerDelivery({
      customerFirstName: customerProfile.firstname,
      customerSurname: customerProfile.surname,
      customerEmail: customerProfile.email,
      address_line1: "Oeno House",
      address_line2: "The Royal Exchange",
      town: "Unit 16-17, Cornhill",
      county: "London",
      postcode: "EC3V 3LL",
      pickup:true
    })


    navigate("/pay");
  }

  const homeDelivery = () => {

    setCustomerBilling({
      customerFirstName: customerProfile.firstname,
      customerSurname: customerProfile.surname,
      customerEmail: customerProfile.email,
      address_line1: customerProfile.address_line1,
      address_line2: customerProfile.address_line2,
      town: customerProfile.town,
      county: customerProfile.county,
      postcode: customerProfile.postcode
    })

    setCustomerDelivery({
      customerFirstName: customerProfile.firstname,
      customerSurname: customerProfile.surname,
      customerEmail: customerProfile.email,
      address_line1: customerProfile.address_line1,
      address_line2: customerProfile.address_line2,
      town: customerProfile.town,
      county: customerProfile.county,
      postcode: customerProfile.postcode,
      pickup:false
    })


    navigate("/pay");
  }


  const getDeliverAddress = () => {
    var retStr = '';
    if (customerProfile.address_line1 != '' && customerProfile.address_line1 != null) {retStr = retStr + customerProfile.address_line1}
    if (customerProfile.address_line2 != ''  && customerProfile.address_line2 != null) {retStr = retStr + ', ' + customerProfile.address_line2}
    if (customerProfile.town != ''  && customerProfile.town != null) {retStr = retStr + ', ' + customerProfile.town}
    if (customerProfile.county != ''  && customerProfile.county != null ) {retStr = retStr + ', ' + customerProfile.county}
    if (customerProfile.postcode != ''  && customerProfile.postcode != null) {retStr = retStr + ', ' + customerProfile.postcode}
    return retStr;

}


  return (
    <S.Container>
      <Header/>
      <S.OneColumnGrid>
        <S.Main>
          <S.MainTitle>CHOOSE <i>your</i><br/> DELIVERY METHOD</S.MainTitle>

          <S.ColumnGrid>
            <S.Main>

                <S.SectionTitle>IN-STORE PICK UP (No delivery charge)</S.SectionTitle><br/><br/>
                <S.SectionText>If you choose in-store pick you will be able to collect from the address below.</S.SectionText><br/><br/>
                <b>Oeno House, The Royal Exchange, Unit 16-17, Cornhill, London, EC3V 3LL.</b><br/><br/>
                <S.SectionText>Pick up in store is available Monday to Friday 11am - 7pm</S.SectionText><br/><br/><br/>

                <S.ActionButton onClick={shopDelivery}>SELECT SHOP PICK UP</S.ActionButton>

            </S.Main>
            <S.Main>

              <S.SectionTitle>HOME DELIVERY</S.SectionTitle><br/><br/>
              <S.SectionText>Your current home delivey address is shown below.</S.SectionText><br/><br/>
              <b>{getDeliverAddress()}</b><br/><br/>
              <S.SectionText>Orders placed before noon we will delivered within 2-3 working days, delivery charge of £12 will be added to the order</S.SectionText><br/><br/><br/>
              <S.ActionButton onClick={homeDelivery}>SELECT HOME DELIVERY</S.ActionButton>


            </S.Main>
          </S.ColumnGrid>



        </S.Main>
      </S.OneColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default App;
