
import formatPrice from 'utils/formatPrice';
import { ICartProduct } from 'models';

import { useCart } from 'contexts/cart-context';

import * as S from './style';

interface IProps {
  product: ICartProduct;
}
const CartProduct = ({ product }: IProps) => {
  const { removeProduct, increaseProductQuantity, decreaseProductQuantity } =
    useCart();
    const {
      sku,
      id,
      name,
      retail_price,
      currencyId,
      currencyFormat,
      color,
      size,
      grape_variety,
      vintage,
      images,
      winery,
      country,
      quantity,
    } = product;

  const handleRemoveProduct = () => removeProduct(product);
  const handleIncreaseProductQuantity = () => increaseProductQuantity(product);
  const handleDecreaseProductQuantity = () => decreaseProductQuantity(product);

  return (
    <S.Container>
      
        <S.CartColumnGrid>
        <S.Title>{quantity}</S.Title>
        <S.Title>
          <div>
            <S.ChangeQuantity
              onClick={handleDecreaseProductQuantity}
              disabled={quantity === 1 ? true : false}
            >
              -
            </S.ChangeQuantity><br/>
            <S.ChangeQuantity onClick={handleIncreaseProductQuantity}>
              +
            </S.ChangeQuantity>
          </div>          
        </S.Title>
        <S.Title>{name}</S.Title>
        <S.Title>{`${currencyFormat}  ${formatPrice(retail_price, currencyId)}`}</S.Title>  
        <S.Title>
          <S.DeleteButton
            onClick={handleRemoveProduct}
            title="remove product from cart"
          />
        </S.Title>      
        </S.CartColumnGrid>      
    </S.Container>
  );
};

export default CartProduct;
