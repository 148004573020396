import styled from 'styled-components/macro';

export const Container = styled.div``;


export const OneColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 50px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 4fr;
  }
`;


export const Main = styled.main``;

export const MainHeader = styled.main`
  display: grid;
  grid-template-columns: 2fr;
  justify-content: end;
  padding: 0 15px;
  font: normal normal 15px/21px 'Montserrat';
  font-weight:700;
`;

export const DisplayText = styled.h3`
  font: italic normal normal 16px/16px 'Crimson Text';
  margin-bottom:5vh;

`;


export const MainTitle = styled.h3`
  margin-top: 2px;
  padding-bottom: 30px;
  font: normal normal normal 44px/58px 'Crimson Text';
  width:100%;
  opacity: 1;
`;


export const ActionButton = styled.button`
  width: 30%;
  border: 0;
  color: #1b1a20;
  text-transform: uppercase;
  background-color: white;
  border:2px solid black;
  border-radius:30px;
  text-align: center;
  padding: 15px 0;
  margin-top: 10px;
  cursor: pointer;
  outline: none;
  font: normal normal bold 24px/28px 'Montserrat';

  transition: background-color 0.2s;

  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    background-color: #000;
    color:white;
  }
`;

