import { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

/* Theme */
import { ThemeProvider } from 'commons/style/styled-components';
import { theme } from 'commons/style/theme';
import GlobalStyle from 'commons/style/global-style';

/* Context Providers */
import { ProductsProvider } from 'contexts/products-context';
import { CartProvider } from 'contexts/cart-context';

import App from 'components/App';
import Detail from 'components/Detail';
import Login from 'components/Login';
import Register from 'components/Register';
import ResetPassword from 'components/ResetPassword';
import ResetPasswordProfile from 'components/ResetPasswordProfile';
import OtpPassword from 'components/OtpPassword';
import ProfileLanding from 'components/ProfileLanding';
import EditProfile from 'components/EditProfile';
import LoginPrompt from 'components/LoginPrompt';
import Pay from 'components/Pay';
import PayThanks from 'components/PayThanks';


import { CustomerProvider } from 'contexts/customer-context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChooseDelivery from 'components/ChooseDelivery';


const root = document.getElementById('root')!;
const container = ReactDOMClient.createRoot(root);


container.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
        <BrowserRouter>
          <ProductsProvider>
            <CartProvider>
              <CustomerProvider>
                <ToastContainer
                  position="top-left"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <Routes>
                  <Route path="/" element={
                    <App />
                  }></Route>
                  <Route path="/detail/:sku" element={
                    <Detail />
                  }></Route>
                  <Route path="/login/" element={
                    <Login />
                  }></Route>
                  <Route path="/register/" element={
                    <Register />
                  }></Route>
                  <Route path="/resetpassword/" element={
                    <ResetPassword />
                  }></Route>
                  <Route path="/resetpasswordprofile/" element={
                    <ResetPasswordProfile />
                  }></Route>
                  <Route path="/otppassword/" element={
                    <OtpPassword />
                  }></Route>
                  <Route path="/profile/" element={
                    <ProfileLanding />
                  }></Route>
                  <Route path="/editprofile/" element={
                    <EditProfile />
                  }></Route>
                  <Route path="/loginprompt/" element={
                    <LoginPrompt />
                  }></Route>
                  <Route path="/choosedelivery/" element={
                    <ChooseDelivery />
                  }></Route>
                  <Route path="/pay/" element={
                    <Pay />
                  }></Route>
                  <Route path="/paymentthanks/" element={
                    <PayThanks />
                  }></Route>
                </Routes>
              </CustomerProvider>
            </CartProvider>
        </ProductsProvider>
  </BrowserRouter>
    </ThemeProvider>
  </StrictMode>
);
