import { useEffect, useState } from 'react';

import * as S from './style';

function App() {


  return (
    <S.Container>   
      <S.FooterMain>
        <S.SixteenColumnGrid>
        <S.SectionBoxBottom>
              <S.OenoIcon/>
          </S.SectionBoxBottom>



          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/app/">App</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/about/">About</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/contact/">Contact</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/press/">Press</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/winenews/">Wine&nbsp;News</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/awards/">Awards</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/investor/">Become&nbsp;an&nbsp;Investor</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/careers/">Careers</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/faq/">FAQ</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/deliveries/">Deliveries</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/terms/">Terms&nbsp;of&nbsp;Use</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/privacy/">Privacy&nbsp;Policy</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <S.a href="https://oenogroup.com/saleterms/">Terms&nbsp;of&nbsp;Sale</S.a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
              <a href="https://www.instagram.com/oeno_official/"><S.InstaLogo/></a>
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
            <a href="https://www.facebook.com/oenoofficial"><S.FacebookLogo/></a>
          </S.SectionBoxBottom>     


        </S.SixteenColumnGrid>          
        <S.SevenColumnGrid>
          <a href="https://apps.apple.com/gb/app/oeno-futures/id1599341511"><S.SectionBoxBottom>
            <S.AppleLogo/>   
          </S.SectionBoxBottom></a>
          <S.SectionBoxBottom>
            <a href="https://play.google.com/store/apps/details?id=com.oeno.phone.app"><S.GoogleLogo/></a>
          </S.SectionBoxBottom>           
          <S.SectionBoxBottom>
          </S.SectionBoxBottom>           
          <S.SectionBoxBottom>            
            <S.CountryTag>London</S.CountryTag>
            <S.TelephoneTag>+44 (0) 207 846 3366</S.TelephoneTag>             
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
            <S.CountryTag>Sao Paulo</S.CountryTag>
            <S.TelephoneTag>+55 (0) 11 4280 5793</S.TelephoneTag>             
          </S.SectionBoxBottom>
          <S.SectionBoxBottom>
            <S.CountryTag>Tuscany</S.CountryTag>
            <S.TelephoneTag>+39 (0) 5771 460 030</S.TelephoneTag>              
          </S.SectionBoxBottom>                       
          <S.SectionBoxBottom>
            <S.CountryTag>New York</S.CountryTag>
            <S.TelephoneTag>+1 (212) 220-6669</S.TelephoneTag>              
          </S.SectionBoxBottom>                       
          <S.SectionBoxBottom>
            <S.CountryTag>Madrid</S.CountryTag>
            <S.TelephoneTag>+34 919 011 959</S.TelephoneTag>               
          </S.SectionBoxBottom>
        </S.SevenColumnGrid>
      </S.FooterMain>
    </S.Container>
  );
}

export default App;
