import styled from 'styled-components/macro';

export const Container = styled.div``;


export const OneColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 50px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 4fr;
  }
`;


export const Main = styled.main``;

export const MainHeader = styled.main`
  display: grid;
  grid-template-columns: 2fr;
  justify-content: end;
  padding: 0 15px;
  font: normal normal 15px/21px 'Montserrat';
  font-weight:700;
`;



export const MainTitle = styled.h3`
  margin-top: 2px;
  padding-bottom: 30px;
  font: normal normal normal 44px/58px 'Crimson Text';
  width:100%;
  opacity: 1;
`;

