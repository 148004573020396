import { useEffect,useState } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';

import { toast } from 'react-toastify';

import { useNavigate } from "react-router-dom";

import Loader from 'components/Loader';

import moment from 'moment';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useCustomer } from 'contexts/customer-context';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';


import * as S from './style';

function App() {
  const { setCustomerLogin,customerLogin,setCustomerPortfolio,setCustomerProfile } = useCustomer();
  const navigate = useNavigate();
  const [ isFetching , setIsFetching ] = useState(false);
  const isProduction = process.env.NODE_ENV === 'production';

  const defaultValues = {
    firstname: "a",
    surname: "a",
    email: "oenouser@yopmail.com",
    telephone: "07985430500",
    password: "password",
    passwordrepeat: "password",
    address1: "1 the street",
    address2: "",
    address3: "",
    town: "town",
    county: "county",
    country: "global",
    postcode: "gu126nj",
    terms: false,
    invest: false,
  };

  const [formValues, setFormValues] = useState(defaultValues)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log(formValues);

    let url = process.env.REACT_APP_BASEURL_PRODUCTION;

    if (!isProduction) {
      url = process.env.REACT_APP_BASEURL_DEVELOPMENT;
    }

    setIsFetching(true);

    axios.post(url + '/api/lead-register',
    {
      firstname: formValues.firstname,
      surname: formValues.surname,
      email: formValues.email,
      telephone: formValues.telephone,
      password: formValues.password,
      confirm_password: formValues.passwordrepeat,
      address_line1: formValues.address1,
      address_line2: formValues.address2,
      address_line3: formValues.address3,
      town: formValues.town,
      county: formValues.county,
      country: 'global',
      postcode: formValues.postcode,
    }).then(
      (rsp) => {
        if (rsp.data.statuscode == 200) {


          console.log(formValues.invest);
          if (formValues.invest) {
            alert(10);
            axios.post(url + '/api/receive-leads',
            {
              country: 'global',
              date_created: moment(),
              email: formValues.email,
              fullname: formValues.firstname + ' ' + formValues.surname,
              phone: formValues.telephone,
              source: 'OENO WEBSITE',
            }).then(
              (rsp) => {console.log('lead response',rsp)
            });

          }


          axios.post(
            url + '/api/phone-login',
          {
            email: formValues.email,
            password: formValues.password,
          }).then(
            (rsp) => {
              if (rsp.data.statuscode == 200) {

                  var token = rsp.data.data.token;


                  setCustomerLogin(rsp.data.data);

                  // Now get the users profile
                  const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + rsp.data.data.token
                  }

                  axios.get(url + '/api/get-portfolio' , { headers: headers}).then(
                    (rsp) => {

                      setCustomerPortfolio(rsp.data.data);

                    }).catch(
                      err => {
                        console.log(err);
                      }
                    );


                  axios.get(url + '/api/get-profile' , { headers: headers}).then(
                    (rsp) => {

                      setCustomerProfile(rsp.data.data.user);

                      setIsFetching(false);
                      navigate("/");


                    }).catch(
                      err => {
                        setIsFetching(false);
                        toast.error('Sorry there seems to be an issue, please try again later');
                      }
                    )





                } else {
                  setIsFetching(false);

                  toast.error('Sorry there seems to be an issue, please try again later');
                }

            }
          ).catch(
            err => {
              console.log(err);
              setIsFetching(false);
              toast.error('Sorry there seems to be an issue, please try again later');
            }
          )


        } else {


          if (rsp.data.message) {
            if (rsp.data.message.email) {
              setIsFetching(false);
              toast.error(rsp.data.message.email[0]);

            }
          }
          else {
            setIsFetching(false);
            toast.error('Sorry there seems to be an issue, please try again later');

          }
        }

      }
    ).catch(
      err => {
        console.log(err);
        toast.error('Sorry there seems to be an issue, please try again later');
      }
    )



  }

  const registerClick = () => {


    navigate("/register");


  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    console.log(name,value)
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    console.log(name,e.target.checked)
    setFormValues({
      ...formValues,
      [name]: e.target.checked,
    });
  };
  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header/>
      <form onSubmit={handleSubmit}>
      <S.ColumnGrid>
        <S.Main>
          <S.MainTitle>REGISTER</S.MainTitle>

          <TextField value={formValues.firstname} name="firstname" fullWidth label="Firstname" variant="standard"   inputProps={{ tabIndex: 1 }} required onChange={handleInputChange}/><br/><br/>

          <TextField value={formValues.email} name="email" fullWidth label="Email" variant="standard" required type="email" inputProps={{ tabIndex: 3 }}  onChange={handleInputChange}/><br/><br/>

          <TextField value={formValues.password} name="password" fullWidth label="Password" variant="standard" type="password" inputProps={{ tabIndex: 5 }} required onChange={handleInputChange}/><br/><br/>

        </S.Main>
        <S.Main>
          <S.MainTitle>&nbsp;</S.MainTitle>

          <TextField value={formValues.surname} name="surname" fullWidth label="Surname" variant="standard" required inputProps={{ tabIndex: 2 }} onChange={handleInputChange}/><br/><br/>
          <TextField value={formValues.telephone} name="telephone"  fullWidth label="Telephone" variant="standard" inputProps={{ tabIndex: 4 }}  onChange={handleInputChange}/><br/><br/>
          <TextField value={formValues.passwordrepeat} name="passwordrepeat" fullWidth label="Repeat Password" variant="standard" type="password" inputProps={{ tabIndex: 6 }} required  onChange={handleInputChange}/><br/><br/>


        </S.Main>
      </S.ColumnGrid>

      <S.ColumnGridOne>
        <S.dividerLine/>
      </S.ColumnGridOne>


      <S.ColumnGrid>
        <S.Main>

          <TextField value={formValues.address1} name="address1"  fullWidth label="Address Line 1" variant="standard" inputProps={{ tabIndex: 7 }} required onChange={handleInputChange}/><br/><br/>
          <TextField value={formValues.town} name="town" fullWidth label="Town" variant="standard" inputProps={{ tabIndex: 9 }}  onChange={handleInputChange}/><br/><br/>
          <TextField value={formValues.postcode} name="postcode" fullWidth label="Postcode" variant="standard" inputProps={{ tabIndex: 11 }} required onChange={handleInputChange}/><br/><br/>



          <FormControlLabel control={<Checkbox value={formValues.terms} required name="terms" inputProps={{ tabIndex: 12 }} onChange={handleCheckBoxChange}/>} label="I agree to the terms and conditions, " /> <a href="https://oenogroup.com/privacy-policy/" target="_new">Click here to view.</a><br/>
          <FormControlLabel control={<Checkbox value={formValues.invest} name="invest" inputProps={{ tabIndex: 13 }} onChange={handleCheckBoxChange} />} label="I am interested in investing in wine" />


        </S.Main>
        <S.Main>

        <TextField value={formValues.address2} name="address2" fullWidth label="Address Line 2" inputProps={{ tabIndex: 8 }} variant="standard" onChange={handleInputChange}/><br/><br/>
        <TextField value={formValues.county} name="county" fullWidth label="County" inputProps={{ tabIndex: 10 }} variant="standard" onChange={handleInputChange}/><br/><br/><br/><br/><br/><br/><br/><br/>

        <S.ActionButton type="submit">REGISTER</S.ActionButton>

        </S.Main>
      </S.ColumnGrid>
      </form>
      <Footer/>
    </S.Container>
  );
}

export default App;
