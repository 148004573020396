import { useCallback } from 'react';

import { useCustomerContext } from './CustomerContextProvider';


const useCustomer = () => {
  const {
    customerLogin,
    setCustomerLogin,
    customerPortfolio,
    setCustomerPortfolio,
    customerProfile,
    setCustomerProfile,
    customerDelivery,
    setCustomerDelivery,
    customerBilling,
    setCustomerBilling
  } = useCustomerContext();

  const clearCustomer = () => {
    console.log('clearCustomer');
    setCustomerLogin({
      token:''
    });
    setCustomerPortfolio({
      retailOrders: {},
      investment: {},
    });
    setCustomerProfile({
      firstname: '',
      surname: '',
      email: '',
      telephone: '',
      password: '',
      passwordrepeat: '',
      address_line1: '',
      address_line2: '',
      address_line3: '',
      town: '',
      county: '',
      postcode: '',
      terms: false,
      invest: false});
  }

  return {
    customerLogin,
    setCustomerLogin,
    customerPortfolio,
    setCustomerPortfolio,
    customerProfile,
    setCustomerProfile,
    clearCustomer,
    customerDelivery,
    setCustomerDelivery,
    customerBilling,
    setCustomerBilling
  };

};





export default useCustomer;
