import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

import { useCustomer } from 'contexts/customer-context';
import { useCart } from 'contexts/cart-context';
import formatPrice from 'utils/formatPrice';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Loader from 'components/Loader';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import * as S from './style';

import PaymentForm from "../../commons/PaymentForm";

function Pay() {


  const { emptyProduct } = useCart();
  const { customerBilling, customerDelivery,customerLogin } = useCustomer();

  const navigate = useNavigate();


  emptyProduct();


  return (
    <S.Container>
      <Header/>
      <S.OneColumnGrid>
        <S.Main>
          <S.MainTitle>THANK YOU <i>for</i> <br/><i>your</i> PAYMENT</S.MainTitle>





        </S.Main>
      </S.OneColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default Pay;
