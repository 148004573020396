import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useNavigate, Link } from "react-router-dom";


import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


import { useCustomer } from 'contexts/customer-context';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import axios from 'axios';
import { toast } from 'react-toastify';

import * as S from './style';

function App() {

  const navigate = useNavigate();



  const { clearCustomer,customerLogin,customerProfile } = useCustomer();

  console.log('customerProfile',customerProfile);


  const registerClick = () => {
    navigate("/register");
  }
  const profileClick = () => {
    navigate("/editprofile");
  }
  const resetClick = () => {
    navigate("/resetpasswordprofile",{ state: customerProfile.email });
  }
  const logoutClick = () => {
    clearCustomer();
    navigate("/");
  }

  const getCustomerName = () => {
    let returnVal = "";
    if (customerProfile) {
      returnVal = customerProfile.firstname + ' ' + customerProfile.surname;
    }
    return returnVal
  }


  return (
    <S.Container>
      <Header/>
      <S.ColumnGrid>
        <S.Main>
          <S.MainTitle>{ getCustomerName() }</S.MainTitle>


          <S.ActionButton onClick={profileClick}>EDIT PROFILE</S.ActionButton><br/><br/>
          <S.ActionButton onClick={resetClick}>RESET PASSWORD</S.ActionButton><br/><br/>
          <S.ActionButton onClick={logoutClick}>LOGOUT</S.ActionButton><br/><br/>


        </S.Main>
      </S.ColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default App;
