import { useEffect } from 'react';
import formatPrice from 'utils/formatPrice';
import CartProducts from './CartProducts';

import { useCart } from 'contexts/cart-context';
import { useNavigate } from "react-router-dom";
import { useCustomer } from 'contexts/customer-context';


import * as S from './style';

const Cart = () => {
  const { products, total, isOpen, openCart, closeCart, updateCartTotal } = useCart();
  const navigate = useNavigate();
  const { customerLogin } = useCustomer();


  const handleCheckout = () => {

    closeCart();

    if (!customerLogin.hasOwnProperty('token')) {
      navigate("/loginprompt");
    } else if (customerLogin.token == '') {
      navigate("/loginprompt");
    } else {
      navigate("/choosedelivery");
    }


  };

  const handleToggleCart = (isOpen: boolean) => () =>
    isOpen ? closeCart() : openCart();

  useEffect(() => { updateCartTotal(JSON.parse(window.sessionStorage.getItem("products") || '[]')); }, []);

  return (
    <S.Container isOpen={isOpen}>
      <S.CartButton onClick={handleToggleCart(isOpen)}>
        {isOpen ? (
          <span>X</span>
        ) : (

          <S.CartIcon>
            <S.CartQuantity title="Products in cart quantity">
              {total.productQuantity}
            </S.CartQuantity>
          </S.CartIcon>
        )}
      </S.CartButton>

      {isOpen && (
        <S.CartContent>
          <S.CartContentHeader>
            <S.CartIconBlack large>
              <S.CartQuantity>{total.productQuantity}</S.CartQuantity>
            </S.CartIconBlack>
            <S.HeaderTitle>Cart</S.HeaderTitle>
          </S.CartContentHeader>

          <CartProducts products={products} />

          <S.CartFooter>
            <S.Sub>SUBTOTAL</S.Sub>
            <S.SubPrice>
              <S.SubPriceValue>{`${total.currencyFormat} ${formatPrice(
                total.totalPrice,
                total.currencyId
              )}`}</S.SubPriceValue>
            </S.SubPrice>
            <S.dividerLine/>
            { total.productQuantity > 0 && <S.CheckoutButton onClick={handleCheckout} autoFocus>
              Check out
            </S.CheckoutButton> }
          </S.CartFooter>
        </S.CartContent>
      )}
    </S.Container>
  );
};

export default Cart;
