import { useEffect,useState } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';

import { toast } from 'react-toastify';

import { useNavigate } from "react-router-dom";

import Loader from 'components/Loader';

import moment from 'moment';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useCustomer } from 'contexts/customer-context';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';


import * as S from './style';

function App() {
  const { customerProfile, setCustomerLogin,customerLogin,setCustomerPortfolio,setCustomerProfile } = useCustomer();
  const navigate = useNavigate();
  const [ isFetching , setIsFetching ] = useState(false);
  const isProduction = process.env.NODE_ENV === 'production';

  console.log(customerProfile)

  const defaultValues = {
    firstname: customerProfile.firstname,
    surname: customerProfile.surname,
    email: customerProfile.email,
    telephone: customerProfile.telephone,
    address1: customerProfile.address_line1,
    address2: customerProfile.address_line2,
    address3: "",
    town: customerProfile.town,
    county: customerProfile.county,
    country: "global",
    postcode: customerProfile.postcode,
  };

  const [formValues, setFormValues] = useState(defaultValues)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log(formValues);

    let url = process.env.REACT_APP_BASEURL_PRODUCTION;

    if (!isProduction) {
      url = process.env.REACT_APP_BASEURL_DEVELOPMENT;
    }

    setIsFetching(true);


    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + customerLogin.token
    }


    axios.post(url + '/api/update-profile',
    {
      firstname: formValues.firstname,
      surname: formValues.surname,
      email: formValues.email,
      telephone: formValues.telephone,
      address_line1: formValues.address1,
      address_line2: formValues.address2,
      address_line3: formValues.address3,
      town: formValues.town,
      county: formValues.county,
      country: 'global',
      postcode: formValues.postcode,
    }, {headers: headers }).then(
      (rsp) => {
        if (rsp.data.statuscode == 200) {

          axios.get(url + '/api/get-profile' , { headers: headers}).then(
            (rsp) => {

              setCustomerProfile(rsp.data.data.user);

              setIsFetching(false);
              navigate("/profile");


            }).catch(
              err => {
                setIsFetching(false);
                toast.error('Sorry there seems to be an issue, please try again later');
              }
            )

        } else {


          if (rsp.data.message) {
            if (rsp.data.message.email) {
              setIsFetching(false);
              toast.error(rsp.data.message.email[0]);

            }
          }
          else {
            setIsFetching(false);
            toast.error('Sorry there seems to be an issue, please try again later');

          }
        }

      }
    ).catch(
      err => {
        console.log(err);
        toast.error('Sorry there seems to be an issue, please try again later');
      }
    )



  }


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header/>
      <S.OneColumnGrid>
        <S.Main>
          <S.MainTitle>EDIT PROFILE</S.MainTitle>

          <form onSubmit={handleSubmit}>
          <TextField value={formValues.firstname} name="firstname" fullWidth label="Firstname" variant="standard" required onChange={handleInputChange}/><br/><br/>

          <TextField value={formValues.surname} name="surname" fullWidth label="Surname" variant="standard" required onChange={handleInputChange}/><br/><br/>

          <TextField value={formValues.email} name="email" fullWidth label="Email" variant="standard" required type="email"  onChange={handleInputChange}/><br/><br/>

          <TextField value={formValues.telephone} name="telephone"  fullWidth label="Telephone" variant="standard"  onChange={handleInputChange}/><br/><br/>

          <TextField value={formValues.address1} name="address1"  fullWidth label="Address Line 1" variant="standard" required onChange={handleInputChange}/><br/><br/>
          <TextField value={formValues.address2} name="address2" fullWidth label="Address Line 2" variant="standard" onChange={handleInputChange}/><br/><br/>
          <TextField value={formValues.town} name="town" fullWidth label="Town" variant="standard"  onChange={handleInputChange}/><br/><br/>
          <TextField value={formValues.county} name="county" fullWidth label="County" variant="standard" onChange={handleInputChange}/><br/><br/>
          <TextField value={formValues.postcode} name="postcode" fullWidth label="Postcode" variant="standard" required onChange={handleInputChange}/><br/><br/>

          <S.ActionButton  type="submit">SAVE</S.ActionButton><br/><br/>
          </form>
        </S.Main>
      </S.OneColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default App;
