import { useEffect } from 'react';
import { useParams } from 'react-router';

import Loader from 'components/Loader';
import Product from 'components/Product';
import Products from 'components/Products';
import ProductCart from 'components/Product/ProductCart';
import Cart from 'components/Cart';
import Profile from 'components/Profile';

import { useProducts } from 'contexts/products-context';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';


import * as S from './style';

function App() {
  const { isFetching, products, fetchProducts } = useProducts();

  const { sku } = useParams();

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header/>
      <S.TwoColumnGrid>
        <S.Main>
        {products.filter(product => product.sku == sku ).map((p) => (
            <Product product={p} key={sku} />
          ))}
        </S.Main>
        <S.Side>
        {products.filter(product => product.sku == sku ).map((p) => (
            <ProductCart product={p} key={sku} />
          ))}
        </S.Side>
      </S.TwoColumnGrid>
      <Footer/>
    </S.Container>
  );
}

export default App;
