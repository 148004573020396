import styled from 'styled-components/macro';

export const Container = styled.div``;



export const FooterMain = styled.main`
  max-width: 100%;
  margin: 50px auto auto;
  padding-bottom:40px;
  padding-top:40px;  
  background-color: #CECECE;

`;




export const SevenColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 0px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const SectionBoxBottom = styled.div`
  display: grid;
  justify-content: right;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const CountryTag = styled.p`
  position: relative;
  margin:0;
  font-weight:700;
  font-family: 'Crimson Text', serif;
  font: italic normal normal 32px/32px 'Crimson Text';
  text-align:left;
  padding-bottom:10px;
`;

export const TelephoneTag = styled.p`
  position: relative;
  margin:0;
  font-size:36px;
  font-weight:700;
  font-family: 'Crimson Text', serif;
  font: normal normal normal 14px/14px 'Crimson Text';
  text-align:left;
`;


export const AppleLogo = styled.div`
  width: 137px;
  height: 45px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url(${require('static/apple-store.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  float:left;

`;



export const GoogleLogo = styled.div`
  width: 144px;
  height: 45px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url(${require('static/google-play.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  float:left;

`;

export const OenoIcon = styled.div`
  width: 45px;
  height: 45px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url(${require('static/oeno-icon.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  float:left;

`;

export const a = styled.a`
  text-decoration: none;
  font-family: 'Montserrat', serif;
  font: normal normal normal 11px/11px 'Montserrat';

`;

export const SixteenColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 0px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;




export const FacebookLogo = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url(${require('static/facebook-icon.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  float:left;

`;



export const InstaLogo = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url(${require('static/insta-con.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  float:left;

`;

















export const ThreeColumnGridInner = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 0px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;












export const ThreeColumnGridNarrow = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 0px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`;

export const ThreeColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 50px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;



export const MiddleSide = styled.div`
  display: grid;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;
export const CartSide = styled.div`
  display: grid;
  justify-content: right;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const CartSideLeft = styled.div`
  display: grid;
  justify-content: left;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;


export const TwoColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 50px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 4fr;
  }
`;

export const Side = styled.div`
  display: grid;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const Main = styled.main``;

export const MainHeader = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  padding: 0 15px;
  font: normal normal 15px/21px 'Montserrat';
  font-weight:700;
`;
